import React from 'react';
import styled from 'styled-components';

const Introduction = () => {
  return (
    <IntroContainer>
      <h3>This website is still under development : )</h3>
      <p>
        I'm a research student supervised by Uta Hinrichs at the University of Edinburgh. Passionate about the intersection of data visualisation, interface design, and Human-Computer Interaction, I am driven to enrich user experiences across the digital and physical realms. I hold an MA in Design Informatics and a BA in Painting.
      </p>
    </IntroContainer>
  );
};

const IntroContainer = styled.div`
  padding: 20px;
  margin: 0 auto; /* Center the container */
  max-width: 600px; /* Limit the width of the container */
  text-align: left;
  background: linear-gradient(to middle, #00fff9, #f5f5f5); /* Gradient background */
  color: #333; /* Ensure the text color matches the theme */
`;

export default Introduction;
